<template>
	<v-row justify="center">
    
    <v-col cols="12" sm="6" md="4">
      <v-card class="fill-height d-flex align-content-space-between rounded-xl" style="flex-direction: column;" color="purple" dark>
        <v-card-title class="headline">Make Drinks / Recipes - 🥃 📜</v-card-title>
        <v-card-subtitle>Make or just discover drinks based on classics, the community, and friends! (Feeling Lucky? 🍀)</v-card-subtitle>
        <v-card-actions class="mt-auto">
          <v-spacer></v-spacer>
          <v-btn text to="/recipes" rounded>
            Recipes
            <v-icon right dark>
              mdi-bottle-tonic-skull-outline
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

		<v-col cols="12" sm="6" md="4">
      <v-card class="fill-height d-flex align-content-space-between rounded-xl" style="flex-direction: column;" dark color="primary">
        <v-card-title class="headline">Add Your Stash 💰</v-card-title>
        <v-card-subtitle>Manage your stash from a central place! So you can focus on what you're making and not what you can make.</v-card-subtitle>
        <v-card-actions class="mt-auto">
          <v-spacer></v-spacer>
          <v-btn text to="/inventory" rounded>
            Ingredients
            <v-icon right dark>
              mdi-clipboard-list
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-col>
    
    <template v-if="user.loggedIn">
      <v-col cols="12" sm="8" md="6">
        <v-card class="fill-height rounded-xl" dark color="indigo">
          <v-card-title class="text-center headline">Metrics 📊</v-card-title>
          <v-card-subtitle>Here's some fun data based off your Recipes and Stash!</v-card-subtitle>
          <v-card-text>
            <div class="text-h5 text-center text-decoration-underline">Stash 🍾</div>
            You have {{inventoryCount == 0 ? `${inventoryCount} bottles` : inventoryCount == 1 ? `${inventoryCount} bottle` : `${inventoryCount} bottles`}} of alcohol, broken down by:
            <div class="text-center">

              <v-chip v-for="(value, name, index) in inventoryByTypes" :key="index" :color="value.primaryColor" class="ma-2" :to="`/inventory?types=${name}`">
                <v-avatar left :class="value.secondaryColor">
                  {{value.count}}
                </v-avatar>
                <span style="text-transform: capitalize">{{`${name} ${value.emoji}`}}</span>
              </v-chip>

            </div>

            <div class="text-h5 text-center text-decoration-underline">Recipes 📜</div>
            You have {{recipeCount == 0 ? `${recipeCount} recipes` : recipeCount == 1 ? `${recipeCount} recipe` : `${recipeCount} recipes`}} recipes. The breakdown by alcohol types is: 
            <div class="text-center">

              <v-chip v-for="(value, name, index) in recipeTypesCount" :key="index" :color="value.primaryColor" class="ma-2" :to="`/recipe/types=${name}`">
                <v-avatar left :class="value.secondaryColor">
                  {{value.count}}
                </v-avatar>
                <span style="text-transform: capitalize">{{`${name} ${value.emoji}`}}</span>
              </v-chip>

            </div> 
          </v-card-text>
        </v-card>
      </v-col> 
      <v-col cols="12" md="8" v-if="inventoryRunningLow.length >= 1">
        <v-card class="fill-height rounded-xl" dark color="cyan">
          <v-card-title class="text-center headline">Ingredients Running Low 📉</v-card-title>
          <v-card-text class="px-0">
            <v-slide-group show-arrows>
              <v-slide-item v-for="(item, index) in inventoryRunningLow" :key="index">
                <v-card flat width="250" dark class="fill-height d-flex align-content-space-between flex-wrap rounded-xl mx-3" style="flex-direction: column;" :color="item.primaryColor">
                  <v-toolbar :color="item.accentColor" class="flex-grow-0" style="text-overflow: ellipsis;" flat dark>
                    <v-toolbar-title style="cursor: pointer" @click="$router.push(`/inventory/${item.id}`)">
                      {{ (item.distiller == "" || item.distiller == null || item.distiller === undefined) ? `${item.maker}` : `${item.distiller} - ${item.maker}`}}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text class="py-1">
                    <p class="text-h6 mb-1" style="font-size: 1rem !important;">
                      {{ `${ item.subvariant ||  ''} ${item.variant || ''} ${item.subtype || ''} ${item.type || ''}`.trim() }}
                    </p>
                    <p class="text-subtitle-2 mb-0">Qty Left</p>
                    <div class="text-center">
                      <v-progress-circular :color="'white'" :rotate="-90" size="100" width="15" :value="item.percentLeft">{{item.percentLeft}}%</v-progress-circular>
                      <!-- <br/><br/> -->
                      <!-- <p class="">{{item.qty+''+item.measurement+' left'}}</p> -->
                    </div>
                  </v-card-text>           
                  <v-card-actions class="mt-auto" height="52px">
                    <v-spacer></v-spacer>
                    <v-btn rounded text color="deep-purple accent-4" :href="item.link || item.searchLink" target="_blank">
                      Buy More
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-card-text>
        </v-card>
      </v-col>
    </template>

	</v-row>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'Home',
    components: {}, 
    computed: {
      ...mapState({
        user: 'user', 
        storeRecipes: 'recipes'
      }), 
      ...mapGetters({
        inventoryByTypes: 'inventoryByTypes', 
        inventoryCount: 'inventoryCount',
        recipeTypesCount: 'recipeTypesCount',
        recipeCount: 'recipeCount', 
        inventoryRunningLow: 'getInventoryRunningLow'
      })
    }, 
    methods: {

    }
  }
</script>
